<template>
    <div>
        <ts-page-title
            :title="$t('attendanceSummary.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('attendanceSummary.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper class="row">
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{ $t("attendanceSummary.dateFrom") }}</label
                    >
                    <DatePicker
                        class="tw-w-full"
                        :value="dateFrom"
                        @on-change="onChangeDateFrom"
                        format="dd-MM-yyyy"
                        type="date"
                        :class="{
                            'ivu-form-item-error': errors.has('date_from')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('date_from')"
                    >
                        {{ errors.first("date_from") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{ $t("attendanceSummary.dateTo") }}</label
                    >
                    <DatePicker
                        class="tw-w-full"
                        :value="dateTo"
                        @on-change="onChangeDateTo"
                        format="dd-MM-yyyy"
                        type="date"
                        :class="{
                            'ivu-form-item-error': errors.has('date_to')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('date_to')"
                    >
                        {{ errors.first("date_to") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{
                            $t("attendanceSummary.organisationalStructure")
                        }}</label
                    >
                    <Select
                        v-model="org_chart_structure_id"
                        clearable
                        @on-change="employee_id = null"
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'org_chart_structure_id'
                            )
                        }"
                    >
                        <Option
                            v-for="(orgChartStructure,
                            index) in orgChartStructures"
                            :key="index"
                            :value="orgChartStructure.org_chart_structure_id"
                            >{{ orgChartStructure.org_chart_structure }}
                        </Option>
                    </Select>
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('org_chart_structure_id')"
                    >
                        {{ errors.first("org_chart_structure_id") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label class="form-col-label control-label tw-mb-2">{{
                        $t("attendanceSummary.employeeName")
                    }}</label>
                    <Select
                        v-model="employee_id"
                        clearable
                        multiple
                        filterable
                        placeholder="All"
                        :loading="EmployeeLoading"
                        :not-found-text="
                            EmployeeLoading ? '' : 'No options available'
                        "
                    >
                        <Option
                            v-for="(employee, index) in employees"
                            :key="index"
                            :value="employee.employee_id"
                            >{{ employee.employee_name_en }}
                        </Option>
                    </Select>
                </div>
                <div class="col-md-2 form-group tw-px-2 tw-pt-7">
                    <Button class="tw-mb-1" type="primary" @click="fetchData"
                        >{{ $t("view") }}
                    </Button>
                    <Button
                        class="tw-mb-1 tw-ml-3"
                        type="success"
                        ghost
                        @click="fetchData"
                    >
                        <Icon type="ios-document-outline" />
                        {{ $t("excel") }}
                    </Button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                border
                stripe
            >
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "attendance-summary",
    components: {},
    data() {
        return {
            errors: new Errors(),
            loading: false,
            EmployeeLoading: false,
            dateFrom: moment()
                .startOf("month")
                .format("DD-MM-YYYY"),
            dateTo: moment()
                .endOf("month")
                .format("DD-MM-YYYY"),
            org_chart_structure_id: null,
            employee_id: null
        };
    },
    computed: {
        ...mapState("report/attendanceSummary", [
            "resources",
            "pagination",
            "orgChartStructures",
            "employees"
        ]),
        columns() {
            return [
                {
                    title: this.$t("attendanceSummary.cardNumber"),
                    key: "card_number"
                },
                {
                    title: this.$t("attendanceSummary.employeeName"),
                    key: "employee_name"
                },
                {
                    title: this.$t("attendanceSummary.position"),
                    key: "position"
                },
                {
                    title: this.$t("attendanceSummary.present"),
                    key: "present",
                    align: "center"
                },
                {
                    title: this.$t("attendanceSummary.absent"),
                    key: "absent",
                    align: "center"
                },
                {
                    title: this.$t("attendanceSummary.takeLeave"),
                    key: "take_leave",
                    align: "center"
                }
            ];
        }
    },
    methods: {
        ...mapActions("report/attendanceSummary", [
            "getOrgChartStructure",
            "getEmployee",
            "clearEmployee"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getOrgChartStructure();
            this.loading = false;
        },
        async getEmployeeByOrg() {
            this.EmployeeLoading = true;
            await this.clearEmployee();
            await this.getEmployee({
                id: this.org_chart_structure_id
                    ? this.org_chart_structure_id
                    : ""
            });
            this.EmployeeLoading = false;
        },
        onChangeDateFrom(data) {
            this.dateFrom = data;
        },
        onChangeDateTo(data) {
            this.dateTo = data;
        },
        async fetchData(attributes) {
            this.loading = true;
            this.errors = new Errors();
            this.$store
                .dispatch("report/attendanceSummary/fetch", {
                    date_from: this.dateFrom,
                    date_to: this.dateTo,
                    org_chart_structure_id: this.org_chart_structure_id,
                    employee_id: this.employee_id,
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "DAILY ATTENDANCES",
                desc: not.text
            });
        }
    },
    watch: {
        org_chart_structure_id: function() {
            this.getEmployeeByOrg();
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("report/attendanceSummary/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    }
};
</script>
